import { apiSlice } from "api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartner: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/partner',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data[0],
      providesTags: ['Partner'],
    }),
  }),
});

export const { useGetPartnerQuery } = extendedApiSlice;
