import React from 'react';

const AboutUs = () => {
  return (
    <div className='md:px-32'>
      <div className='grid grid-cols-12'>
        <div className='col-span-12 md:col-span-6 order-2 md:order-1 space-y-4 lg:space-y-8 px-4 '>
          <h1 className='font-bold text-xl md:text-2xl lg:text-3xl mt-4 md:mt-0'>
            About Us
          </h1>
          <div className='space-y-2 lg:space-y-4 '>
            <p className='lg:text-lg'>
              We enable talent to go global from their rooms.
            </p>
            <p>
              Belong is an employability marketplace that facilitates a seamless
              ecosystem of opportunities for young talent, global employers and
              universities across the globe.
            </p>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6 order-1 md:order-2 md:w-4/5 -mx-2 md:mx-auto'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1660888819/landing/about_wt7juk.jpg'
            alt='about-belong'
            className='rounded shadow'
          />
        </div>
      </div>
      <div className='grid grid-cols-12  gap-y-8 md:gap-x-8 px-4  my-10 lg:my-16 lg:leading-7'>
        <div className='col-span-12 md:col-span-4'>
          <p>
            For young <span className='text-primary'>talent</span> it’s a
            platform which enables them to access virtual work experiences,
            upskilling courses and live projects with organisations globally.
          </p>
        </div>
        <div className='col-span-12 md:col-span-4'>
          <p>
            The talent marketplace gives
            <span className='text-primary'> Employers</span> a unique
            opportunity engage with an international audience on a single
            platform in critical, strategic and market entry related projects.
          </p>
        </div>
        <div className='col-span-12 md:col-span-4'>
          <p>
            <span className='text-primary'> Universities</span> can build custom
            graduate readiness programs relevant to their student base and
            access faculty, industry experts, placement opportunities and global
            academic programs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
