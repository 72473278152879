import React from 'react';

const Logo = ({ partnerLogo }: { partnerLogo?: string }) => {
  return (
    <div>
      <img
        src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
        alt='Belong'
        className='hidden w-40 md:inline-flex'
      />
      <img
        src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
        alt='Belong'
        className='md:hidden w-16 inline-flex'
      />

      {partnerLogo && (
        <img
          src={partnerLogo}
          alt='Belong'
          className='inline-flex mx-6 w-28 md:w-28 h-24 object-contain'
        />
      )}
    </div>
  );
};

export default Logo;
