import React, { useState } from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import ChangePassword from './ChangePassword';
import Modal from 'react-modal';
import { customStyles } from '../../../profile/ModalStyles';

const ApplicationSettings = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className='flex flex-col justify-between flex-1 bg-gradient-to-b from-gray-100 to-primary-lighter shadow-lg rounded-xl'>
      <div className='flex p-4'>
        <div className='grid place-items-center px-8'>
          <AiOutlineSetting className='text-4xl font-semibold' />
        </div>
        <div className='text-center flex-1'>
          <p className='font-semibold'>Application Settings</p>
          <p className='text-xs'>
            Change your Email, Password, Phone Number etc
          </p>
        </div>
      </div>

      <div className='flex justify-between items-center shadow rounded-xl mt-4 bg-primary-lightest px-4 py-2'>
        <p className='text-xs w-3/5'>
          Customise settings as per your Prefrence
        </p>
        <button onClick={() => openModal()} className='font-semibold'>
          Edit Settings
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}
      >
        <div>
          <ChangePassword closeModal={closeModal} />
        </div>
      </Modal>
    </div>
  );
};

export default ApplicationSettings;
